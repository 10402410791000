<template>
    <div class="recom">
        <div class="recom-container" v-if="showPage">
            <van-list
                v-model="loading"
                @load="onLoad"
                :finished="finished"
                :immediate-check="false"
                finished-text="没有更多了"
            >
                <goods-list :data="goods" @click="clickDetail" />
            </van-list>
        </div>
        <van-empty image="https://khome2.tuzuu.com/klfront/newlogo.png" description="正在加载..." v-else="showPage"/>
        <tab-bar :active="1" /> 
    </div>
</template>

<script>
import TabBar from "@/components/tabbar";
import GoodsList from "@/components/GoodsList";
export default {
    name: "Recom",
    data: () => ({
        goods: [],
        loading: false,
        finished: false,
        over: false,
        page: 1,
        showPage: false
    }),
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            if (!this.over) {
                const res = await axios.get("/goods_list", {
                    params:{
                        page: this.page,
                        cate_id: "1",
                    }
                });
                if (res.code == 1) {
                    this.showPage = true;
                    if (res.data.goods.length === 0) {
                        this.over = true;
                        this.finished = true;
                        return;
                    }
                    this.loading = false;
                    this.page++;
                    this.goods.push(...res.data.goods);
                }
            }
        },
        clickDetail(data) {
            this.$router.push("/detail/" + data.id);
        },
        onLoad() {
            this.getData();
        },
    },
    components: {
        TabBar,
        GoodsList,
    },
};
</script>

<style lang="less" scoped>
.recom {
    width: 100%;
    height: 100%;
    color: #333;

    .recom-container {
        padding-bottom: 70px;
        box-sizing: border-box;
    }
    .van-empty {
        height: 100%;
        /deep/.van-empty__description {
            color: rgb(142,205,88);
            font-size: 15px;
            margin: 0;
        }
    }
}
</style>
